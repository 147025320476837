import { memo, useCallback, useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SearchIcon } from "theme/icons";
import { DESKTOP_VIEW } from "utils/constants";

const SearchBar = ({ onSearch }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchPhrase = searchParams.get("q") || "";

  const [phrase, setPhrase] = useState("");

  const handleChange = useCallback(
    (event) => {
      setPhrase(event.target.value);
    },
    [setPhrase]
  );

  /** --- Fix the losing of the last letter in search input --- */
  useEffect(() => {
    handleChange;
  }, [handleChange]);

  useEffect(() => {
    searchPhrase ? setPhrase(searchPhrase) : setPhrase("");
  }, [searchPhrase]);

  const showResults = (e) => {
    if (e.key === "Enter" || e.type === "click") {
      /** -- client side search --- */
      if (onSearch) {
        onSearch(phrase);
        return;
      }
      /** -- setting search phrase for server side search in DataTable --- */
      searchParams.set("q", phrase);
      setSearchParams(searchParams);
    }
  };

  return (
    <TextField
      variant="outlined"
      type="search"
      name="q"
      autoComplete="off"
      sx={{
        flex: 1,
        ...(!isDesktop && {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "extra.white",
          },
        }),
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"} sx={{ ml: 0 }}>
            <IconButton onClick={showResults}>
              <SearchIcon stroke={theme.palette.primary.main} fill="none" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={t("base.placeholders.search")}
      onChange={handleChange}
      onKeyDown={showResults}
      value={phrase}
    />
  );
};

export default memo(SearchBar);
