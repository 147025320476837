import { createSlice } from '@reduxjs/toolkit';

/** --- Slice for handling DataGrid component --- */

const tableSlice = createSlice({
  name: 'tableSlice',
  initialState: {
    selected: [],
  },
  reducers: {
    addSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const { addSelected, addSearchPhrase } = tableSlice.actions;
export default tableSlice.reducer;
